import { useTheme, useMediaQuery } from '@mui/material'
export const sensorDisplay = (sensor) =>
  `${sensor.location}: ${sensor.building.name} - ${sensor.unit.number} (${sensor.unit.floor})`
export const unitDisplay = (unit) => `${unit.building.name} - ${unit.number} (${unit.floor})`

export const buildingDisplay = (building) => building.name

export const getOrdinal = (n) => {
  let ord = 'th'

  if (n % 10 === 1 && n % 100 !== 11) {
    ord = 'st'
  } else if (n % 10 === 2 && n % 100 !== 12) {
    ord = 'nd'
  } else if (n % 10 === 3 && n % 100 !== 13) {
    ord = 'rd'
  }

  return ord
}

// Check to see if we are on a mobile device
export const isMobile = () => useMediaQuery(useTheme().breakpoints.down('sm'))

import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBuildingQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetBuildingQuery = { __typename?: 'Query', getBuilding: { __typename?: 'Building', id: string, buildingType: string } };

export type FetchAlertsQueryVariables = Types.Exact<{
  query?: Types.InputMaybe<Types.Scalars['String']>;
  eventFilter?: Types.InputMaybe<Types.Scalars['String']>;
  pagination?: Types.InputMaybe<Types.PaginationType>;
  buildingId?: Types.InputMaybe<Types.Scalars['ID']>;
  reportFilters?: Types.InputMaybe<Types.WidgetFiltersType>;
}>;


export type FetchAlertsQuery = { __typename?: 'Query', fetchAlerts: { __typename?: 'AlertCollection', metadata: { __typename?: 'CollectionMetadata', currentPage: number, limitValue: number, totalCount: number, totalPages: number }, collection: Array<{ __typename?: 'Alert', alertType: Types.AlertType, sensorEvent: { __typename?: 'SensorEvent', temperature?: number | null, humidity?: number | null, sensor: { __typename?: 'Sensor', name?: string | null, deviceName?: string | null, location?: string | null, unit?: { __typename?: 'Unit', number?: string | null } | null } }, userAlerts: Array<{ __typename?: 'UserAlert', sentAt: unknown, acknowledgedAt?: unknown | null, sentSms: boolean, sentEmail: boolean, sentMobile: boolean, notSentOutsideHours: boolean, user: { __typename?: 'User', firstName?: string | null, lastName?: string | null } }> }> } };


export const GetBuildingDocument = gql`
    query GetBuilding($id: ID!) {
  getBuilding(id: $id) {
    id
    buildingType
  }
}
    `;

/**
 * __useGetBuildingQuery__
 *
 * To run a query within a React component, call `useGetBuildingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuildingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuildingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBuildingQuery(baseOptions: Apollo.QueryHookOptions<GetBuildingQuery, GetBuildingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBuildingQuery, GetBuildingQueryVariables>(GetBuildingDocument, options);
      }
export function useGetBuildingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBuildingQuery, GetBuildingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBuildingQuery, GetBuildingQueryVariables>(GetBuildingDocument, options);
        }
export type GetBuildingQueryHookResult = ReturnType<typeof useGetBuildingQuery>;
export type GetBuildingLazyQueryHookResult = ReturnType<typeof useGetBuildingLazyQuery>;
export type GetBuildingQueryResult = Apollo.QueryResult<GetBuildingQuery, GetBuildingQueryVariables>;
export const FetchAlertsDocument = gql`
    query FetchAlerts($query: String, $eventFilter: String, $pagination: PaginationType, $buildingId: ID, $reportFilters: WidgetFiltersType) {
  fetchAlerts(
    query: $query
    eventFilter: $eventFilter
    pagination: $pagination
    buildingId: $buildingId
    reportFilters: $reportFilters
  ) {
    metadata {
      currentPage
      limitValue
      totalCount
      totalPages
    }
    collection {
      alertType
      sensorEvent {
        temperature
        humidity
        sensor {
          name
          deviceName
          location
          unit {
            number
          }
        }
      }
      userAlerts {
        sentAt
        acknowledgedAt
        sentSms
        sentEmail
        sentMobile
        notSentOutsideHours
        user {
          firstName
          lastName
        }
      }
    }
  }
}
    `;

/**
 * __useFetchAlertsQuery__
 *
 * To run a query within a React component, call `useFetchAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAlertsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      eventFilter: // value for 'eventFilter'
 *      pagination: // value for 'pagination'
 *      buildingId: // value for 'buildingId'
 *      reportFilters: // value for 'reportFilters'
 *   },
 * });
 */
export function useFetchAlertsQuery(baseOptions?: Apollo.QueryHookOptions<FetchAlertsQuery, FetchAlertsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchAlertsQuery, FetchAlertsQueryVariables>(FetchAlertsDocument, options);
      }
export function useFetchAlertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchAlertsQuery, FetchAlertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchAlertsQuery, FetchAlertsQueryVariables>(FetchAlertsDocument, options);
        }
export type FetchAlertsQueryHookResult = ReturnType<typeof useFetchAlertsQuery>;
export type FetchAlertsLazyQueryHookResult = ReturnType<typeof useFetchAlertsLazyQuery>;
export type FetchAlertsQueryResult = Apollo.QueryResult<FetchAlertsQuery, FetchAlertsQueryVariables>;
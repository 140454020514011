import React, { Fragment, useRef, useState, useEffect, useCallback } from 'react'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableCell,
  Grid,
  CircularProgress,
  Typography
} from '@mui/material'
import { isEmpty } from 'lodash'

import { unitLabel } from '../../../common/utils/buildings'
import usePagination from '../../../common/hooks/usePagination'
import { StyledTableCell } from '../../../common/theme/components/tables/StyledTableCell'
import { StyledTableRow } from '../../../common/theme/components/tables/StyledTableRow'

import EventTypeFilter from '../../atoms/EventTypeFilter/EventTypeFilter'
import SearchBar from '../../atoms/SearchBar/SearchBar'
import AlertListRow from '../../molecules/AlertListRow'

import useAlertsTable from './useAlertsTable'
import { TEMPERATURE_LABEL, HUMIDITY_LABEL } from '../../../common/constants/reports'

const AlertsTable = ({
  building,
  sensor,
  hideUnitColumn = false,
  numRowsPerPage,
  tableContainerSx = {},
  tableSx = {},
  showNoRecordsMessage = true,
  showSearchBar = false,
  showEventTypeFilter = false,
  reportFilters,
  reportToken,
  isPrinting,
  alertsTableProps, // State variable and setter to pass search and eventfilter back
  setAlertsTableProps // to parent
}) => {
  const { page, setPage, rowsPerPage, handleChangePage, handleChangeRowsPerPage, rowsPerPageOptions } =
    usePagination({ numRowsPerPage })

  const {
    alerts,
    metadata,
    hasTemperature,
    hasHumidity,
    query,
    setQuery,
    eventFilter,
    setEventFilter,
    loading
  } = useAlertsTable({
    buildingId: building?.id,
    sensorId: sensor?.id,
    reportFilters: reportFilters,
    reportToken: reportToken,
    page,
    setPage,
    rowsPerPage,
    isPrinting
  })

  const setQueryFull = (query) => {
    // Used to allow parent to read query value
    if (setAlertsTableProps) setAlertsTableProps({ ...alertsTableProps, query: query })
    setQuery(query)
  }

  const setEventFilterFull = (eventFilter) => {
    // Used to allow parent to read eventFilter value
    if (setAlertsTableProps) setAlertsTableProps({ ...alertsTableProps, eventFilter: eventFilter })
    setEventFilter(eventFilter)
  }

  const numberOfColumns = 11 // used for "No Alerts Recorded" message
  return (
    <TableContainer sx={tableContainerSx}>
      <Grid container alignItems='center' spacing={3}>
        {showSearchBar && <SearchBar placeholder={'Search Alerts'} query={query} setQuery={setQueryFull} />}
        {showEventTypeFilter && (
          <EventTypeFilter eventFilter={eventFilter} setEventFilter={setEventFilterFull} />
        )}
        <Grid item xs={8} />
      </Grid>
      <Table aria-label='collapsible table' sx={tableSx} className='dataTables_filter'>
        <TableHead>
          <TableRow>
            <StyledTableCell />
            {reportFilters && <StyledTableCell>Building</StyledTableCell>}
            {!hideUnitColumn && <StyledTableCell>{unitLabel(building)}</StyledTableCell>}
            <StyledTableCell>Sensor Name</StyledTableCell>
            <StyledTableCell>Sensor Location</StyledTableCell>
            <StyledTableCell align='center'>Alert For</StyledTableCell>
            <StyledTableCell>Severity</StyledTableCell>
            {hasTemperature && <StyledTableCell>{TEMPERATURE_LABEL}</StyledTableCell>}
            {hasHumidity && <StyledTableCell>{HUMIDITY_LABEL}</StyledTableCell>}
            <StyledTableCell>Users Alerted</StyledTableCell>
            <StyledTableCell>Sent At or Event Time</StyledTableCell>
            <StyledTableCell>Acknowledged</StyledTableCell>
            <StyledTableCell>Sent Via</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody className='semi-tight-table-row'>
          {showNoRecordsMessage && isEmpty(alerts) && (
            <StyledTableRow hover>
              <TableCell align='center' sx={{ verticalAlign: 'top' }} colSpan={numberOfColumns}>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Typography component={'h4'}>No Alerts Recorded</Typography>
                )}
              </TableCell>
            </StyledTableRow>
          )}

          {alerts.map((alert) => (
            <Fragment key={alert.createdAt}>
              <AlertListRow
                alert={alert}
                hasTemperature={hasTemperature}
                hasHumidity={hasHumidity}
                hideUnitColumn={hideUnitColumn}
                hasBuilding={reportFilters !== undefined}
                isPrinting={isPrinting}
              />
            </Fragment>
          ))}
        </TableBody>
      </Table>
      {!isPrinting && (
        <TablePagination
          colSpan={4}
          rowsPerPageOptions={rowsPerPageOptions}
          component='div'
          count={metadata?.totalCount || 0}
          rowsPerPage={rowsPerPage}
          page={isEmpty(metadata) ? 0 : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </TableContainer>
  )
}

export default AlertsTable

import React from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import { Scanner } from '@yudiel/react-qr-scanner'

const ScanQrCodeDialog = ({ qrModalOpen, setQrModalOpen, handleCloseScanQr }) => (
  <Dialog open={qrModalOpen} onClose={handleCloseScanQr}>
    <DialogTitle>Sensor QR Code Scan</DialogTitle>
    <DialogContent>
      <Scanner
        onScan={(scanResult) => handleCloseScanQr({ scanResult })}
        components={{
          audio: true,
          onOff: true,
          torch: true,
          zoom: true,
          finder: true
        }}
        allowMultiple={true}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setQrModalOpen(false)} variant='outlined' color='primary'>
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
)

export default ScanQrCodeDialog

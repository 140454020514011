export const ALERT_MONITOR_TYPE_CLEANING_REQUEST = 'Cleaning Request'
export const ALERT_MONITOR_TYPE_DOOR_CLOSED = 'Door Closed'
export const ALERT_MONITOR_TYPE_DOOR_OPEN = 'Door Open'
export const ALERT_MONITOR_TYPE_POWER = 'Power'
export const ALERT_MONITOR_TYPE_SUPERVISORY = 'Device Check-In'
export const ALERT_MONITOR_TYPE_TEMPERATURE = 'Temperature'
export const ALERT_MONITOR_TYPE_HUMIDITY = 'Humidity'
export const ALERT_MONITOR_TYPE_WATER = 'Water'
export const ALERT_MONITOR_TYPE_WATER_CLEARED = 'Water Cleared'
export const ALERT_MONITOR_TYPE_SOUND = 'Sound Event'
export const EVENT_TYPE_CLEANING_REQUEST = 'cleaning_request'
export const EVENT_TYPE_DOOR_CLOSED = 'door_closed'
export const EVENT_TYPE_DOOR_OPEN = 'door_open'
export const EVENT_TYPE_HIGH_TEMPERATURE = 'high_temperature'
export const EVENT_TYPE_LOW_TEMPERATURE = 'low_temperature'
export const EVENT_TYPE_HIGH_HUMIDITY = 'high_humidity'
export const EVENT_TYPE_LOW_HUMIDITY = 'low_humidity'
export const EVENT_TYPE_POWER = 'power'
export const EVENT_TYPE_SOUND = 'sound'
export const EVENT_TYPE_SUPERVISORY = 'supervisory'
export const EVENT_TYPE_WATER = 'water'
export const SEVERITY_TYPE_CRITICAL = 'critical'
export const SEVERITY_TYPE_HIGH = 'high'
export const SEVERITY_TYPE_MEDIUM = 'medium'
export const SEVERITY_TYPE_LOW = 'low'
export const ALL_SEVERITIES = [
  SEVERITY_TYPE_LOW,
  SEVERITY_TYPE_MEDIUM,
  SEVERITY_TYPE_HIGH,
  SEVERITY_TYPE_CRITICAL
]

// Those 0-3 values map to LOW->CRITICAL levels
export const SEVERITY_LABELS = {
  0: 'No risk of damage or flooding',
  1: 'Chance of damage or flooding if event had not been caught',
  2: 'Risk of serious repercussions if event had not been caught',
  3: 'Event would have resulted in major damage and potentially a large insurance claim'
}

import React from 'react'
import { Controller } from 'react-hook-form'
import {
  Button,
  TextField,
  Box,
  Checkbox,
  Typography,
  FormControlLabel,
  MenuItem,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material'
import useCreateEditValve from './useCreateEditValve'
import SensorsTable from '../../organisms/SensorsTable'
import { get } from 'lodash'
import { NUMBER_OF_ROWS_PER_PAGE_FIVE } from '../../../common/constants/pagination'

const ValveForm = ({ building }) => {
  const { handleSubmit, control, errors, projects, onSubmit } = useCreateEditValve({
    building,
    context: 'create'
  })
  console.log('asdsadaadsasdbuilding')
  console.log(building)

  // TODO: Enable loading existing valve or setup for new valve to be added.
  const valveId = -1
  return (
    <Grid container spacing={1} justifyContent='center'>
      <Grid item xs={4} md={4}>
        <Box
          component='form'
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ m: 'auto', pt: 2, textAlign: 'center', minWidth: '350px', maxWidth: '500px' }}
        >
          <Typography variant={'h5'} sx={{ mb: 2 }}>
            Valve Details
          </Typography>
          <Controller
            name='make'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                margin='dense'
                label='Make'
                type='text'
                fullWidth
                error={!!errors.make}
                helperText={errors.make?.message}
              />
            )}
          />
          <Controller
            name='model'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                margin='dense'
                label='Model'
                type='text'
                fullWidth
                error={!!errors.model}
                helperText={errors.model?.message}
              />
            )}
          />
          <Controller
            name='location'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                margin='dense'
                label='Location'
                type='text'
                fullWidth
                error={!!errors.location}
                helperText={errors.location?.message}
              />
            )}
          />
          <Controller
            name='hardwareId'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                margin='dense'
                label='Hardware ID'
                type='text'
                fullWidth
                error={!!errors.hardwareId}
                helperText={errors.hardwareId?.message}
              />
            )}
          />
          <Controller
            name='projectId'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                margin='dense'
                label='Project'
                sx={{
                  mb: 3,
                  textAlign: 'left',
                  '& .MuiSelect-select': {
                    alignItems: 'left'
                  }
                }}
                fullWidth
                select
                error={!!errors.projectId}
                helperText={errors.projectId?.message}
              >
                {projects.map((project) => (
                  <MenuItem key={project.id} value={project.id}>
                    {project.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Button sx={{ mr: 2 }} variant='contained' type='submit'>
            Add Valve
          </Button>
          <Button variant='outlined' onClick={() => window.history.back()}>
            Cancel
          </Button>
        </Box>
      </Grid>
      <Grid item xs={8} md={8}>
        <Box sx={{ m: 'auto', pt: 2, textAlign: 'center', minWidth: '350px' }}>
          <Typography variant={'h5'} sx={{ mb: 2 }}>
            Associated Water Sensors
          </Typography>
          <Paper sx={{ p: 2 }}>
            <SensorsTable
              building={building}
              valveId={valveId}
              displayColumns={['deviceName', 'location', 'unit', 'lastSeen', 'battery', 'hardwareId']}
              // userType={get(occupantData, ['getCurrentUser', 'type'])}
              // hideUnitColumn={hideUnitColumn}
              numRowsPerPage={NUMBER_OF_ROWS_PER_PAGE_FIVE}
            />
          </Paper>
        </Box>
      </Grid>
    </Grid>
  )
}
export default ValveForm

import React, { useEffect, useState } from 'react'
import Webcam from 'react-webcam'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'

const CaptureSensorPhotoModal = ({
  imageModalOpen,
  setImageModalOpen,
  imageSrc,
  setImageSrc,
  capture,
  webcamRef
}) => {
  // Ensure that camera zoom is reset incase QR code camera was zoomed in
  const setZoomOut = (track) => {
    if (track && typeof track.getCapabilities === 'function') {
      const capabilities = track.getCapabilities()
      if (capabilities.zoom) {
        const settings = track.getSettings()
        track
          .applyConstraints({
            advanced: [{ zoom: capabilities.zoom.min }]
          })
          .then(() => {
            console.log(`Zoom set to: ${settings.zoom}`)
          })
          .catch((err) => {
            console.error(`Error setting zoom: ${err.message}`)
          })
      }
    }
  }

  const handleUserMedia = (stream) => {
    const videoTrack = stream.getVideoTracks()[0]
    setZoomOut(videoTrack)
  }

  const [cameras, setCameras] = useState([])

  useEffect(() => {
    const getCameras = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices()
        setCameras(devices.filter((device) => device.kind === 'videoinput'))
      } catch (error) {
        console.error('Error accessing media devices.', error)
      }
    }
    getCameras()
  }, [])

  return (
    <Dialog open={imageModalOpen} onClose={() => setImageModalOpen(false)}>
      <DialogTitle>Take Sensor Photo</DialogTitle>
      <DialogContent
        sx={{
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {imageSrc ? (
          <img
            src={imageSrc}
            alt='Captured'
            sx={{
              width: '100%',
              maxHeight: '300px',
              marginBottom: '1rem'
            }}
          />
        ) : (
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat='image/jpeg'
            videoConstraints={cameras.length > 1 ? { facingMode: { exact: 'environment' } } : {}}
            onUserMedia={handleUserMedia}
            sx={{
              width: '100%', // Makes webcam preview responsive
              height: 'auto', // Maintains aspect ratio
              maxHeight: '80vh'
            }}
          />
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', mb: 1 }}>
        {!imageSrc && (
          <Button variant='contained' color='primary' onClick={capture}>
            Capture Photo
          </Button>
        )}
        {imageSrc && (
          <Button variant='contained' color='secondary' onClick={() => setImageSrc(null)}>
            Retake Photo
          </Button>
        )}
        <Button onClick={() => setImageModalOpen(false)} variant='outlined' color='primary'>
          {imageSrc ? 'Done' : 'Cancel'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CaptureSensorPhotoModal

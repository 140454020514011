import { get } from 'lodash'

import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
// import { useUpdateValveMutation } from '../GraphQl/gql.generated'
import { useCreateValveMutation } from '../../pages/Valves/GraphQl/gql.generated'
import { useFetchProjectListQuery } from '../../organisms/GatewaysTable/GraphQl/gql.generated'

const schema = yup
  .object({
    make: yup.string().required('Make is required'),
    model: yup.string().required('Model is required'),
    location: yup.string().required('Location is required'),
    hardwareId: yup.string().required('Hardware ID is required'),
    buildingId: yup.string().required('Building ID is required'),
    unitId: yup.string().required('Zone is required'),
    projectId: yup.string().nullable()
  })
  .required()

const useCreateEditValve = ({ valve, building, context }) => {
  const [localValve, setLocalValve] = useState(valve)

  useEffect(() => {
    setLocalValve(valve)
  }, [valve])

  const { data } = useFetchProjectListQuery({
    variables: {
      buildingId: context === 'create' ? building.id : localValve.buildingId
    }
  })

  const defaultValues =
    context === 'edit'
      ? {
          make: localValve.make,
          model: localValve.model,
          location: localValve.location,
          serialNumber: localValve.serialNumber,
          hardwareId: localValve.hardwareId,
          buildingId: localValve.buildingId,
          inUse: localValve.inUse,
          projectId: localValve.projectId
        }
      : {
          make: '',
          model: '',
          location: '',
          hardwareId: '',
          projectId: '',
          buildingId: building.id,
          unitId: ''
        }

  const projects = get(data, ['fetchProjects', 'collection'], [])

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues
  })

  // const [editValve] = useUpdateValveMutation()
  const [createValve] = useCreateValveMutation()
  const onSubmit = (input) => {
    createValve({
      variables: {
        input
      },
      refetchQueries: ['FetchValves']
    }).then(() => {
      handleClose()
    })
    reset()
    // if (context === 'edit') {
    //   editValve({
    //     variables: {
    //       input: {
    //         ...input,
    //         id: localValve.id
    //       }
    //     },
    //     refetchQueries: ['FetchValves'],
    //     onCompleted: (data) => {
    //       const updatedValve = data.updateValve
    //       setLocalValve(updatedValve)
    //       reset({
    //         make: updatedValve.make,
    //         model: updatedValve.model,
    //         location: updatedValve.location,
    //         serialNumber: updatedValve.serialNumber,
    //         hardwareId: updatedValve.hardwareId,
    //         buildingId: updatedValve.buildingId,
    //         inUse: updatedValve.inUse,
    //         projectId: updatedValve.projectId
    //       })
    //     }
    //   })
    // } else {
    //
    // }
  }

  // const resetValues =
  //   context === 'edit'
  //     ? {
  //         make: localValve.make,
  //         model: localValve.model,
  //         location: localValve.location,
  //         serialNumber: localValve.serialNumber,
  //         hardwareId: localValve.hardwareId,
  //         buildingId: localValve.buildingId,
  //         inUse: localValve.inUse,
  //         projectId: localValve.projectId
  //       }
  //     : {
  //         make: null,
  //         model: null,
  //         location: null,
  //         serialNumber: null,
  //         hardwareId: null,
  //         projectId: null,
  //         buildingId: buildingId,
  //         inUse: true
  //       }

  // const isSuperadmin = isTypeSuperadmin(userType)
  // const isPropertyAdmin = isTypePropertyAdmin(userType)

  return {
    // isSuperadmin,
    // isPropertyAdmin,
    control,
    handleSubmit,
    errors,
    projects,
    onSubmit
  }
}

export default useCreateEditValve

import React from 'react'
import ValveForm from '../../../molecules/ValveForm'
import { useParams } from 'react-router-dom'
import { useGetBuildingQuery } from '../../GatewaysPage/GraphQl/gql.generated'

const CreateValvePage = () => {
  const { buildingId } = useParams()
  const { data, loading } = useGetBuildingQuery({
    variables: { id: buildingId }
  })

  const building = data?.getBuilding
  return !loading && <ValveForm building={building}></ValveForm>
}
export default CreateValvePage

import React from 'react'
import { StyledTableRow } from '../../../common/theme/components/tables/StyledTableRow'
import MenuDropdown from '../MenuDropdown'

import { MenuItem } from '@mui/material'
import { StyledTableCell } from '../../../common/theme/components/tables/StyledTableCell'

const ValveListRow = ({ valve }) => {
  return (
    <React.Fragment>
      <StyledTableRow hover>
        <StyledTableCell align='left'>{valve.make}</StyledTableCell>
        <StyledTableCell align='left'>{valve.model}</StyledTableCell>
        <StyledTableCell align='left'>{valve.location}</StyledTableCell>
        <StyledTableCell align='left'>{valve.isOpen ? 'Open' : 'Closed'}</StyledTableCell>
        <StyledTableCell align='left'>{valve.unit.number}</StyledTableCell>
        <StyledTableCell align='left'>{valve.hardwareId}</StyledTableCell>
        <StyledTableCell align='right' style={{ paddingBottom: 0, paddingTop: 0 }}>
          <MenuDropdown>
            <MenuItem onClick={() => {}}>Edit</MenuItem>
            {/*<MenuItem onClick={()=> {}}>Delete</MenuItem>*/}
          </MenuDropdown>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  )
}

export default ValveListRow

import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchValvesQueryVariables = Types.Exact<{
  query?: Types.InputMaybe<Types.Scalars['String']>;
  pagination?: Types.InputMaybe<Types.PaginationType>;
  buildingId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type FetchValvesQuery = { __typename?: 'Query', fetchValves: { __typename?: 'ValveCollection', metadata: { __typename?: 'CollectionMetadata', currentPage: number, limitValue: number, totalCount: number, totalPages: number }, collection: Array<{ __typename?: 'Valve', id: string, make: string, model: string, location: string, isOpen: boolean, hardwareId: string, unit: { __typename?: 'Unit', number?: string | null } }> } };

export type CreateValveMutationVariables = Types.Exact<{
  input: Types.CreateValveInput;
}>;


export type CreateValveMutation = { __typename?: 'Mutation', createValve: { __typename?: 'Valve', id: string, buildingId: string, unitId: string, hardwareId: string, location: string, make: string, model: string, isOpen: boolean, project?: { __typename?: 'Project', id: string } | null } };


export const FetchValvesDocument = gql`
    query FetchValves($query: String, $pagination: PaginationType, $buildingId: ID) {
  fetchValves(query: $query, pagination: $pagination, buildingId: $buildingId) {
    metadata {
      currentPage
      limitValue
      totalCount
      totalPages
    }
    collection {
      id
      make
      model
      location
      isOpen
      hardwareId
      unit {
        number
      }
    }
  }
}
    `;

/**
 * __useFetchValvesQuery__
 *
 * To run a query within a React component, call `useFetchValvesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchValvesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchValvesQuery({
 *   variables: {
 *      query: // value for 'query'
 *      pagination: // value for 'pagination'
 *      buildingId: // value for 'buildingId'
 *   },
 * });
 */
export function useFetchValvesQuery(baseOptions?: Apollo.QueryHookOptions<FetchValvesQuery, FetchValvesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchValvesQuery, FetchValvesQueryVariables>(FetchValvesDocument, options);
      }
export function useFetchValvesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchValvesQuery, FetchValvesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchValvesQuery, FetchValvesQueryVariables>(FetchValvesDocument, options);
        }
export type FetchValvesQueryHookResult = ReturnType<typeof useFetchValvesQuery>;
export type FetchValvesLazyQueryHookResult = ReturnType<typeof useFetchValvesLazyQuery>;
export type FetchValvesQueryResult = Apollo.QueryResult<FetchValvesQuery, FetchValvesQueryVariables>;
export const CreateValveDocument = gql`
    mutation CreateValve($input: CreateValveInput!) {
  createValve(input: $input) {
    id
    buildingId
    unitId
    hardwareId
    location
    make
    model
    isOpen
    project {
      id
    }
  }
}
    `;
export type CreateValveMutationFn = Apollo.MutationFunction<CreateValveMutation, CreateValveMutationVariables>;

/**
 * __useCreateValveMutation__
 *
 * To run a mutation, you first call `useCreateValveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateValveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createValveMutation, { data, loading, error }] = useCreateValveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateValveMutation(baseOptions?: Apollo.MutationHookOptions<CreateValveMutation, CreateValveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateValveMutation, CreateValveMutationVariables>(CreateValveDocument, options);
      }
export type CreateValveMutationHookResult = ReturnType<typeof useCreateValveMutation>;
export type CreateValveMutationResult = Apollo.MutationResult<CreateValveMutation>;
export type CreateValveMutationOptions = Apollo.BaseMutationOptions<CreateValveMutation, CreateValveMutationVariables>;
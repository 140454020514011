import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import {
  Box,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  FormHelperText,
  Link
} from '@mui/material'
import { capitalize, get, isEmpty } from 'lodash'
import { useGetCurrentUserQuery } from '../dashboards/DashboardsList/gql.generated'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import { Controller } from 'react-hook-form'
import CreateUnitModal from '../../organisms/Units/CreateUnitModal'
import { useFetchUnitsLazyQuery } from '../dashboards/components/DashboardFiltersPanel/gql.generated'
import { getOrdinal, sensorDisplay } from '../../../common/utils/display'
import { useSensorQrLookupLazyQuery } from './GraphQl/gql.generated'
import FormCheckbox from '../../atoms/CheckmarkCell'
import TooltipIcon from '../../atoms/TooltopIcon'
import ConfigSlider from '../../molecules/Sliders/ConfigSlider'
import {
  WATER_SENSITIVITY_SLIDER_MARKS,
  TEMPERATURE_SLIDER_MARKS,
  HUMIDITY_SLIDER_MARKS
} from '../../../common/constants/sensors'
import CaptureSensorPhotoModal from './CaptureSensorPhotoModal'
import OverlaySpinner from '../../atoms/OverlaySpinner'
import ScanQrCodeDialog from '../../molecules/ScanQrCodeDialog'
import useSensorCreate from './useSensorCreate'
import TimeConfig from '../../molecules/TimeConfig'
import PopupMessage from '../../molecules/NotificationDialog'

const SensorCreatePage = () => {
  const [qrModalOpen, setQrModalOpen] = useState(false)
  const [imageModalOpen, setImageModalOpen] = useState(false)
  const [showUnitModal, setShowUnitModal] = useState(false)
  const [hoursValues, setHoursValues] = useState({})
  const [submitError, setSubmitError] = useState('')
  const [scanWarning, setScanWarning] = useState('')
  const [units, setUnits] = useState([])
  const [sensorPurpose, setSensorPurpose] = useState('')
  const [imageSrc, setImageSrc] = useState(null)
  const [sensorCapabilities, setSensorCapabilities] = useState([])
  const webcamRef = useRef(null)
  const useQuery = new URLSearchParams(useLocation().search)
  const buildingId = useQuery.get('building_id')

  const { data: userData, loading: userLoading } = useGetCurrentUserQuery()
  const [sensorQrLookup, { loading: qrLookupLoading }] = useSensorQrLookupLazyQuery()
  const [getUnits] = useFetchUnitsLazyQuery({
    variables: {
      buildingIds: [buildingId],
      pagination: {
        per: 300
      }
    }
  })

  const {
    control,
    handleSubmit,
    errors,
    setValue,
    watch,
    handleSensorCreateSubmit,
    tempRange,
    setTempRange,
    humidityRange,
    setHumidityRange,
    waterSensitivity,
    setWaterSensitivity,
    savingSensor
  } = useSensorCreate()

  const hardwareId = watch('hardwareId', '')
  const inUseEnabled = watch('inUse', false)
  const tempEnabled = watch('monitorTemperature', false)
  const humidityEnabled = watch('monitorHumidity', false)
  const waterEnabled = watch('monitorWater', false)
  const doorEnabled = watch('monitorDoor', false)

  const onSubmit = (input) =>
    handleSensorCreateSubmit(
      input,
      setSubmitError,
      buildingId,
      waterEnabled,
      tempEnabled,
      humidityEnabled,
      doorEnabled,
      hoursValues
    )

  const handleAddUnit = (newUnit) => {
    setUnits([...units, newUnit])
    setValue('unitId', newUnit.id, { shouldValidate: true })
    setShowUnitModal(false)
  }

  const capture = useCallback(() => {
    const capturedImageSrc = webcamRef.current.getScreenshot()
    setImageSrc(capturedImageSrc)
    setValue('image', capturedImageSrc)
  }, [webcamRef])
  useEffect(() => {
    const user = userData?.getCurrentUser
    if (!user?.userBuildings.some((ub) => ub.building.id === buildingId)) {
    }
    getUnits().then((responseData) => setUnits(get(responseData, ['data', 'fetchUnits'], [])))

    //   Temporary set to skip QR code scanning while building form
    // handleCloseScanQr({ scanResult: [{ rawValue: '1234567890' }] })
  }, [userLoading])

  const handleCloseScanQr = ({ scanResult = [] }) => {
    setScanWarning('')
    if (!isEmpty(scanResult) && scanResult[0].rawValue !== '') {
      console.log(scanResult[0].rawValue)
      sensorQrLookup({
        variables: {
          qrRawValue: scanResult[0].rawValue
        },
        onCompleted: (qrData) => {
          const sensor = get(qrData, ['sensorQrLookup'], {})
          if (!isEmpty(sensor)) {
            // If sensor already exists we let the user know they would be moving it to another location/building
            if (sensor.deployed) {
              setScanWarning(
                'Sensor already exists, if you continue you will be moving this sensor to your ' +
                  'new deployment location. Please ensure you want to move this sensor before proceeding. The sensor is ' +
                  'currently located at: ' +
                  sensorDisplay(sensor)
              )
            }
            // setHardwareId()
            setValue('hardwareId', sensor.eui, { shouldValidate: true })
            setValue('deviceName', sensor.manufacturer + ': ' + sensor.model, { shouldValidate: true })
            // For now we do not support onboarding for light and motion sensors as the backend is not
            // setup to handle these just yet
            setSensorCapabilities(sensor.capabilities.filter((c) => c !== 'light' && c !== 'motion'))
          }
        },
        onError: (error) => {
          setSubmitError(error.message)
        }
      })
    } else {
      setScanWarning('QR Code is not readable, please try again')
    }
    setQrModalOpen(false)
  }

  // TODO: Add project to the create form, and make it intelligent so it knows the project of the last created sensor so the user doesn't have to complete that field every time
  // TODO: Give the ability to view the picture taken full screen by clicking on it
  return (
    <>
      <PopupMessage
        popupName={'sensorOnboardingNotification'}
        message={
          'For setting up brand new sensors, please ensure you have completed and saved the setup form before you pull the sensor battery tab.'
        }
      />
      <Box sx={{ m: 'auto', pt: 2, textAlign: 'center' }}>
        <Typography variant='h5' component='h5' sx={{ pb: 2 }}>
          Add New Sensor
        </Typography>
        {submitError !== '' && (
          <p className='error-text'>
            {submitError}
            <br />
            <br />
          </p>
        )}
        {scanWarning !== '' && (
          <Box
            className='warning-text'
            component='p'
            sx={{ m: 'auto', textAlign: 'center', maxWidth: '500px' }}
          >
            {scanWarning}
            <br />
            <br />
          </Box>
        )}
        {hardwareId === '' && (
          <>
            <Button variant='contained' onClick={() => setQrModalOpen(true)}>
              <QrCodeScannerIcon />
              &nbsp;&nbsp;Scan Sensor QR
            </Button>
            <Typography sx={{ mt: 2 }}>
              Don't have a QR Code?{' '}
              <Link href={`/sensors/new?building_id=${buildingId}&legacy_create=true`}>
                Enter Sensor Manually
              </Link>
            </Typography>
          </>
        )}
        {hardwareId !== '' && (
          <Box
            component='form'
            onSubmit={handleSubmit(onSubmit)}
            sx={{ minWidth: '350px', maxWidth: '500px', m: 'auto' }}
            noValidate
            autoComplete='off'
          >
            <Controller
              name='hardwareId'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{ mb: 2 }}
                  InputProps={{ readOnly: true }}
                  id='hardwareId'
                  label='Hardware ID*'
                  error={!!errors.hardwareId}
                  helperText={errors.hardwareId ? errors.hardwareId.message : ''}
                  fullWidth
                />
              )}
            />
            <Controller
              name='deviceName'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{ mb: 2 }}
                  InputProps={{ readOnly: true }}
                  id='deviceName'
                  label='Make & Model*'
                  error={!!errors.deviceName}
                  helperText={
                    errors.deviceName
                      ? errors.deviceName.message
                      : 'Capabilities: ' + sensorCapabilities.map((c) => capitalize(c)).join(', ')
                  }
                  fullWidth
                />
              )}
            />
            <Box>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <FormControl fullWidth>
                    <Controller
                      name='unitId'
                      control={control}
                      render={({ field }) => (
                        <>
                          <InputLabel>Zone*</InputLabel>
                          <Select
                            {...field}
                            id='unitId'
                            label='Zone*'
                            variant='outlined'
                            error={!!errors.unitId}
                            fullWidth
                            sx={{
                              textAlign: 'left',
                              '& .MuiSelect-select': {
                                alignItems: 'left'
                              }
                            }}
                          >
                            {units.map((unit, index) => (
                              <MenuItem key={index} value={unit.id}>
                                {unit.number} - {unit.floor + getOrdinal(unit.floor)} floor
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4} sx={{ m: 'auto' }}>
                  <Button
                    sx={{ p: 1.5 }}
                    fullWidth
                    variant='contained'
                    color='primary'
                    onClick={() => setShowUnitModal(true)}
                  >
                    Add a Zone
                  </Button>
                </Grid>
              </Grid>
              <FormHelperText sx={{ ml: 2, mb: 1 }} htmlFor='render-select' error={!!errors.unitId}>
                {errors.unitId ? errors.unitId.message : 'Zone where the sensor is located'}
              </FormHelperText>
            </Box>
            <Controller
              name='location'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id='location'
                  sx={{ mb: 1 }}
                  label='Location*'
                  error={!!errors.location}
                  helperText={
                    errors.location ? errors.location.message : 'Sensor Installation Location within Zone'
                  }
                  fullWidth
                />
              )}
            />
            <Grid container>
              <Grid item>
                <FormControl fullWidth>
                  <Controller
                    name={'inUse'}
                    control={control}
                    render={({ field }) => (
                      <FormCheckbox
                        label={
                          <Grid container direction='row' alignItems='center'>
                            <Grid item>
                              <Typography>In Use</Typography>
                            </Grid>
                            <Grid item>
                              <TooltipIcon
                                title={
                                  'In Use indicates that the sensor should be collecting data. If this is ' +
                                  'disabled, messages arriving from this sensor will be ignored.'
                                }
                                textFontSize={14}
                              />
                            </Grid>
                          </Grid>
                        }
                        checked={field.value}
                        onChange={(e) => setValue('inUse', e.target.checked)}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl fullWidth>
                  <Controller
                    name='enableAlerts'
                    control={control}
                    render={({ field }) => (
                      <FormCheckbox
                        label={
                          <Grid container direction='row' alignItems='center'>
                            <Grid item>
                              <Typography>Enable Alerts</Typography>
                            </Grid>
                            <Grid item>
                              <TooltipIcon
                                title={
                                  'Enable Alerts controls whether alerts are being sent out for the sensor. ' +
                                  'If "In Use" is disabled, then this flag will be ignored as data will not be ' +
                                  'collected. Disabling alerts can be for sensors where we want to collect data ' +
                                  'about the sensor but not send any alerts.'
                                }
                                textFontSize={14}
                              />
                            </Grid>
                          </Grid>
                        }
                        disabled={!inUseEnabled}
                        checked={field.value}
                        onChange={(e) => setValue('enableAlerts', e.target.checked)}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={10} sx={{ pt: 2, pb: 1 }}>
                <FormControl fullWidth>
                  <InputLabel>What will this sensor monitor?</InputLabel>
                  <Select
                    disabled={qrLookupLoading}
                    value={sensorPurpose}
                    label='What will this sensor monitor?'
                    onChange={(event) => {
                      setSensorPurpose(event.target.value)
                      if (event.target.value === 'leak') {
                        setValue('monitorWater', true)
                        setValue('monitorDoor', false)
                        setValue('probeType', 'pin')
                      } else if (event.target.value === 'door') {
                        setValue('monitorWater', false)
                        setValue('monitorDoor', true)
                        setValue('probeType', 'door')
                      } else {
                        setValue('monitorWater', false)
                        setValue('monitorDoor', false)
                        setValue('probeType', '')
                      }
                    }}
                    sx={{
                      textAlign: 'left',
                      '& .MuiSelect-select': {
                        alignItems: 'left'
                      }
                    }}
                  >
                    {sensorCapabilities.map((option) => (
                      <MenuItem value={option} key={option}>
                        {capitalize(option)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {['leak', 'door'].includes(sensorPurpose) && (
                <Grid item xs={8} sx={{ pt: 2, pb: 1 }}>
                  <Controller
                    name='probeType'
                    control={control}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel>Probe Type</InputLabel>
                        <Select
                          {...field}
                          disabled={qrLookupLoading}
                          name='probeType'
                          label='Probe Type'
                          sx={{
                            textAlign: 'left',
                            '& .MuiSelect-select': {
                              alignItems: 'left'
                            }
                          }}
                        >
                          {sensorPurpose === 'leak' &&
                            ['pin', 'rope'].map((option) => (
                              <MenuItem value={option} key={option}>
                                {capitalize(option)}
                              </MenuItem>
                            ))}
                          {sensorPurpose === 'door' && (
                            <MenuItem value={'door'} key={'door'}>
                              Door Probe
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Grid>
              )}
            </Grid>
            {sensorPurpose === 'leak' && (
              <ConfigSlider
                control={control}
                sliderEnabled={waterEnabled}
                sliderValue={waterSensitivity}
                setSliderValue={setWaterSensitivity}
                setValue={setValue}
                min={0}
                max={60}
                marks={WATER_SENSITIVITY_SLIDER_MARKS}
                title={'Water'}
                fieldId={'monitorWater'}
                tooltip={
                  'Only send an alert if the device detects water continuously for the specified number of minutes.' +
                  ' 0 minutes is most sensitive, 60 minutes is least sensitive. This helps prevent alerts from things' +
                  ' like floors being mopped or occasional condensation drips that are expected.'
                }
              />
            )}
            {sensorPurpose === 'door' && (
              <TimeConfig
                control={control}
                doorEnabled={doorEnabled}
                hoursValues={hoursValues}
                setHoursValues={setHoursValues}
                setValue={setValue}
                title={'Door'}
                fieldId={'monitorDoor'}
                tooltip={
                  'Set the hours of the day during which you wish this sensor to send door alerts. ' +
                  'Note that alerts must also be enabled for this sensor to send door alerts.'
                }
              />
            )}
            {['temperature', 'humidity', 'leak', 'door'].includes(sensorPurpose) && (
              <>
                <ConfigSlider
                  control={control}
                  sliderEnabled={tempEnabled}
                  sliderValue={tempRange}
                  setSliderValue={setTempRange}
                  setValue={setValue}
                  min={-50}
                  max={100}
                  marks={TEMPERATURE_SLIDER_MARKS}
                  title={'Temperature'}
                  fieldId={'monitorTemperature'}
                  tooltip={
                    'Device will send alerts if the temperature rises or falls outside the specified range.'
                  }
                />
                <ConfigSlider
                  control={control}
                  sliderEnabled={humidityEnabled}
                  sliderValue={humidityRange}
                  setSliderValue={setHumidityRange}
                  setValue={setValue}
                  min={0}
                  max={100}
                  marks={HUMIDITY_SLIDER_MARKS}
                  title={'Humidity'}
                  fieldId={'monitorHumidity'}
                  tooltip={
                    'Device will send alerts if the humidity rises or falls outside the specified range.'
                  }
                />
                <Box sx={{ textAlign: 'left' }}>
                  {imageSrc && <img src={imageSrc} alt='Captured' width={350} />}
                  <Button
                    sx={{ mt: 2 }}
                    color='primary'
                    variant='contained'
                    onClick={() => {
                      setImageSrc(null)
                      setImageModalOpen(true)
                    }}
                  >
                    <AddAPhotoIcon />
                    &nbsp;&nbsp;{imageSrc ? 'Retake ' : 'Take '}Photo of Installed Sensor
                  </Button>
                </Box>
                {savingSensor ? (
                  <OverlaySpinner
                    open={savingSensor}
                    messageLine1={
                      'Please wait while the sensor is being added to the building. This may take a few seconds.'
                    }
                    messageLine2={
                      'After this step completes, you can pull the battery tab from the sensor to bring it online.'
                    }
                  />
                ) : (
                  <Box sx={{ pb: 5, pt: 4 }}>
                    {!isEmpty(errors) && (
                      <Box className={'warning-text'} sx={{ pb: 3, textAlign: 'left' }}>
                        Ensure all required form fields are completed
                      </Box>
                    )}
                    <Button type='submit' variant='contained' color='primary' style={{ marginRight: 10 }}>
                      Save Sensor
                    </Button>
                    <Button
                      variant='outlined'
                      color='primary'
                      onClick={() => (window.location.href = '/sensors?building_id=' + buildingId.toString())}
                    >
                      Cancel
                    </Button>
                  </Box>
                )}
              </>
            )}
          </Box>
        )}

        <CreateUnitModal
          buildingId={buildingId}
          showUnitModal={showUnitModal}
          setShowUnitModal={setShowUnitModal}
          handleAddUnit={handleAddUnit}
        />

        <ScanQrCodeDialog
          handleCloseScanQr={handleCloseScanQr}
          qrModalOpen={qrModalOpen}
          setQrModalOpen={setQrModalOpen}
        />

        <CaptureSensorPhotoModal
          imageModalOpen={imageModalOpen}
          setImageModalOpen={setImageModalOpen}
          imageSrc={imageSrc}
          setImageSrc={setImageSrc}
          capture={capture}
          webcamRef={webcamRef}
        />
      </Box>
    </>
  )
}

export default SensorCreatePage

import { get, isNil } from 'lodash'
import { getSensorHumidityRangeString, getSensorTemperatureRangeString } from '../../../common/utils/sensors'

const useSensorShowInformation = ({ sensor }) => {
  const waterSensitivity = get(sensor, ['waterSensitivity'], null)
  const waterLabel = isNil(waterSensitivity) ? 'Water' : `Water (${waterSensitivity} minute wait)`

  const temperatureRangeString = getSensorTemperatureRangeString(sensor)
  const temperatureLabel = temperatureRangeString ? `Temperature (${temperatureRangeString})` : 'Temperature'

  const humidityRangeString = getSensorHumidityRangeString(sensor)
  const humidityLabel = humidityRangeString ? `Humidity (${humidityRangeString})` : 'Humidity'

  return {
    waterLabel,
    temperatureLabel,
    humidityLabel
  }
}

export default useSensorShowInformation
